import { LecturerList } from "./LecturerList";

export const KanaSp = ({clickKana, clickKanaChild, lecturerList, selectLecturer, selectedKana, selectedKanaChild}) =>
{
    const getDisabled = (kanaIndexList, lecturerList) =>
    {
        let hasChild = false;
        kanaIndexList.forEach(kana => {
            if(lecturerList[kana]){
                hasChild = true;
            }
        });
        if(hasChild){
            return '';
        }else{
            return ' disabled';
        }
    }

    return (
        <ul id="kanaListSp">
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ア'] ? ' opened' : '') + getDisabled(['ア','イ','ウ','エ','オ'], lecturerList)} onClick={clickKana} data-kana="ア" id="seminar_main_seminar_lecturer_kana_index_ア_sp">ア行</div>
                <div className={'kanaListIndex' + (selectedKana['ア'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ア_sp"
                        data-kana="ア"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ア'] ? ' opened' : '') + (!lecturerList['ア'] ? ' disabled' : '')}
                        data-open="lecturerList-ア"
                    >ア</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ア">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ア'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ア']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_イ_sp"
                        data-kana="イ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['イ'] ? ' opened' : '') + (!lecturerList['イ'] ? ' disabled' : '')}
                        data-open="lecturerList-イ"
                    >イ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-イ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['イ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['イ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ウ_sp"
                        data-kana="ウ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ウ'] ? ' opened' : '') + (!lecturerList['ウ'] ? ' disabled' : '')}
                        data-open="lecturerList-ウ"
                    >ウ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ウ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ウ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ウ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_エ_sp"
                        data-kana="エ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['エ'] ? ' opened' : '') + (!lecturerList['エ'] ? ' disabled' : '')}
                        data-open="lecturerList-エ"
                    >エ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-エ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['エ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['エ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_オ_sp"
                        data-kana="オ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['オ'] ? ' opened' : '') + (!lecturerList['オ'] ? ' disabled' : '')}
                        data-open="lecturerList-オ"
                    >オ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-オ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['オ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['オ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['カ'] ? ' opened' : '') + getDisabled(['カ','キ','ク','ケ','コ'], lecturerList)} onClick={clickKana} data-kana="カ" id="seminar_main_seminar_lecturer_kana_index_カ_sp">カ行</div>
                <div className={'kanaListIndex' + (selectedKana['カ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_カ_sp"
                        data-kana="カ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['カ'] ? ' opened' : '') + (!lecturerList['カ'] ? ' disabled' : '')}
                        data-open="lecturerList-カ"
                    >カ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-カ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['カ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['カ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_キ_sp"
                        data-kana="キ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['キ'] ? ' opened' : '') + (!lecturerList['キ'] ? ' disabled' : '')}
                        data-open="lecturerList-キ"
                    >キ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-キ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['キ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['キ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ク_sp"
                        data-kana="ク"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ク'] ? ' opened' : '') + (!lecturerList['ク'] ? ' disabled' : '')}
                        data-open="lecturerList-ク"
                    >ク</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ク">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ク'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ク']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ケ_sp"
                        data-kana="ケ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ケ'] ? ' opened' : '') + (!lecturerList['ケ'] ? ' disabled' : '')}
                        data-open="lecturerList-ケ"
                    >ケ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ケ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ケ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ケ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_コ_sp"
                        data-kana="コ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['コ'] ? ' opened' : '') + (!lecturerList['コ'] ? ' disabled' : '')}
                        data-open="lecturerList-コ"
                    >コ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-コ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['コ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['コ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['サ'] ? ' opened' : '') + getDisabled(['サ','シ','ス','セ','ソ'], lecturerList)} onClick={clickKana} data-kana="サ" id="seminar_main_seminar_lecturer_kana_index_サ_sp">サ行</div>
                <div className={'kanaListIndex' + (selectedKana['サ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_サ_sp"
                        data-kana="サ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['サ'] ? ' opened' : '') + (!lecturerList['サ'] ? ' disabled' : '')}
                        data-open="lecturerList-サ"
                    >サ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-サ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['サ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['サ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_シ_sp"
                        data-kana="シ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['シ'] ? ' opened' : '') + (!lecturerList['シ'] ? ' disabled' : '')}
                        data-open="lecturerList-シ"
                    >シ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-シ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['シ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['シ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ス_sp"
                        data-kana="ス"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ス'] ? ' opened' : '') + (!lecturerList['ス'] ? ' disabled' : '')}
                        data-open="lecturerList-ス"
                    >ス</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ス">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ス'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ス']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_セ_sp"
                        data-kana="セ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['セ'] ? ' opened' : '') + (!lecturerList['セ'] ? ' disabled' : '')}
                        data-open="lecturerList-セ"
                    >セ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-セ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['セ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['セ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ソ_sp"
                        data-kana="ソ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ソ'] ? ' opened' : '') + (!lecturerList['ソ'] ? ' disabled' : '')}
                        data-open="lecturerList-ソ"
                    >ソ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ソ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ソ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ソ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['タ'] ? ' opened' : '') + getDisabled(['タ','チ','ツ','テ','ト'], lecturerList)} onClick={clickKana} data-kana="タ" id="seminar_main_seminar_lecturer_kana_index_タ_sp">タ行</div>
                <div className={'kanaListIndex' + (selectedKana['タ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_タ_sp"
                        data-kana="タ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['タ'] ? ' opened' : '') + (!lecturerList['タ'] ? ' disabled' : '')}
                        data-open="lecturerList-タ"
                    >タ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-タ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['タ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['タ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_チ_sp"
                        data-kana="チ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['チ'] ? ' opened' : '') + (!lecturerList['チ'] ? ' disabled' : '')}
                        data-open="lecturerList-チ"
                    >チ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-チ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['チ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['チ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ツ_sp"
                        data-kana="ツ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ツ'] ? ' opened' : '') + (!lecturerList['ツ'] ? ' disabled' : '')}
                        data-open="lecturerList-ツ"
                    >ツ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ツ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ツ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ツ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_テ_sp"
                        data-kana="テ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['テ'] ? ' opened' : '') + (!lecturerList['テ'] ? ' disabled' : '')}
                        data-open="lecturerList-テ"
                    >テ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-テ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['テ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['テ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ト_sp"
                        data-kana="ト"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ト'] ? ' opened' : '') + (!lecturerList['ト'] ? ' disabled' : '')}
                        data-open="lecturerList-ト"
                    >ト</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ト">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ト'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ト']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ナ'] ? ' opened' : '') + getDisabled(['ナ','ニ','ヌ','ネ','ノ'], lecturerList)} onClick={clickKana} data-kana="ナ" id="seminar_main_seminar_lecturer_kana_index_ナ_sp">ナ行</div>
                <div className={'kanaListIndex' + (selectedKana['ナ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ナ_sp"
                        data-kana="ナ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ナ'] ? ' opened' : '') + (!lecturerList['ナ'] ? ' disabled' : '')}
                        data-open="lecturerList-ナ"
                    >ナ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ナ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ナ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ナ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ニ_sp"
                        data-kana="ニ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ニ'] ? ' opened' : '') + (!lecturerList['ニ'] ? ' disabled' : '')}
                        data-open="lecturerList-ニ"
                    >ニ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ニ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ニ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ニ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ヌ_sp"
                        data-kana="ヌ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ヌ'] ? ' opened' : '') + (!lecturerList['ヌ'] ? ' disabled' : '')}
                        data-open="lecturerList-ヌ"
                    >ヌ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ヌ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ヌ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ヌ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ネ_sp"
                        data-kana="ネ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ネ'] ? ' opened' : '') + (!lecturerList['ネ'] ? ' disabled' : '')}
                        data-open="lecturerList-ネ"
                    >ネ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ネ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ネ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ネ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ノ_sp"
                        data-kana="ノ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ノ'] ? ' opened' : '') + (!lecturerList['ノ'] ? ' disabled' : '')}
                        data-open="lecturerList-ノ"
                    >ノ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ノ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ノ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ノ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ハ'] ? ' opened' : '') + getDisabled(['ハ','ヒ','フ','ヘ','ホ'], lecturerList)} onClick={clickKana} data-kana="ハ" id="seminar_main_seminar_lecturer_kana_index_ハ_sp">ハ行</div>
                <div className={'kanaListIndex' + (selectedKana['ハ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ハ_sp"
                        data-kana="ハ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ハ'] ? ' opened' : '') + (!lecturerList['ハ'] ? ' disabled' : '')}
                        data-open="lecturerList-ハ"
                    >ハ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ハ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ハ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ハ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ヒ_sp"
                        data-kana="ヒ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ヒ'] ? ' opened' : '') + (!lecturerList['ヒ'] ? ' disabled' : '')}
                        data-open="lecturerList-ヒ"
                    >ヒ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ヒ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ヒ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ヒ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_フ_sp"
                        data-kana="フ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['フ'] ? ' opened' : '') + (!lecturerList['フ'] ? ' disabled' : '')}
                        data-open="lecturerList-フ"
                    >フ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-フ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['フ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['フ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ヘ_sp"
                        data-kana="ヘ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ヘ'] ? ' opened' : '') + (!lecturerList['ヘ'] ? ' disabled' : '')}
                        data-open="lecturerList-ヘ"
                    >ヘ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ヘ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ヘ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ヘ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ホ_sp"
                        data-kana="ホ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ホ'] ? ' opened' : '') + (!lecturerList['ホ'] ? ' disabled' : '')}
                        data-open="lecturerList-ホ"
                    >ホ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ホ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ホ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ホ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['マ'] ? ' opened' : '') + getDisabled(['マ','ミ','ム','メ','モ'], lecturerList)} onClick={clickKana} data-kana="マ" id="seminar_main_seminar_lecturer_kana_index_マ_sp">マ行</div>
                <div className={'kanaListIndex' + (selectedKana['マ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_マ_sp"
                        data-kana="マ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['マ'] ? ' opened' : '') + (!lecturerList['マ'] ? ' disabled' : '')}
                        data-open="lecturerList-マ"
                    >マ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-マ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['マ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['マ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ミ_sp"
                        data-kana="ミ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ミ'] ? ' opened' : '') + (!lecturerList['ミ'] ? ' disabled' : '')}
                        data-open="lecturerList-ミ"
                    >ミ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ミ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ミ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ミ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ム_sp"
                        data-kana="ム"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ム'] ? ' opened' : '') + (!lecturerList['ム'] ? ' disabled' : '')}
                        data-open="lecturerList-ム"
                    >ム</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ム">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ム'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ム']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_メ_sp"
                        data-kana="メ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['メ'] ? ' opened' : '') + (!lecturerList['メ'] ? ' disabled' : '')}
                        data-open="lecturerList-メ"
                    >メ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-メ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['メ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['メ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_モ_sp"
                        data-kana="モ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['モ'] ? ' opened' : '') + (!lecturerList['モ'] ? ' disabled' : '')}
                        data-open="lecturerList-モ"
                    >モ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-モ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['モ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['モ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ヤ'] ? ' opened' : '') + getDisabled(['ヤ','ユ','ヨ'], lecturerList)} onClick={clickKana} data-kana="ヤ" id="seminar_main_seminar_lecturer_kana_index_ヤ_sp">ヤ行</div>
                <div className={'kanaListIndex' + (selectedKana['ヤ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ヤ_sp"
                        data-kana="ヤ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ヤ'] ? ' opened' : '') + (!lecturerList['ヤ'] ? ' disabled' : '')}
                        data-open="lecturerList-ヤ"
                    >ヤ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ヤ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ヤ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ヤ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ユ_sp"
                        data-kana="ユ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ユ'] ? ' opened' : '') + (!lecturerList['ユ'] ? ' disabled' : '')}
                        data-open="lecturerList-ユ"
                    >ユ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ユ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ユ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ユ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ヨ_sp"
                        data-kana="ヨ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ヨ'] ? ' opened' : '') + (!lecturerList['ヨ'] ? ' disabled' : '')}
                        data-open="lecturerList-ヨ"
                    >ヨ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ヨ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ヨ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ヨ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ラ'] ? ' opened' : '') + getDisabled(['ラ','リ','ル','レ','ロ'], lecturerList)} onClick={clickKana} data-kana="ラ" id="seminar_main_seminar_lecturer_kana_index_ラ_sp">ラ行</div>
                <div className={'kanaListIndex' + (selectedKana['ラ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ラ_sp"
                        data-kana="ラ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ラ'] ? ' opened' : '') + (!lecturerList['ラ'] ? ' disabled' : '')}
                        data-open="lecturerList-ラ"
                    >ラ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ラ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ラ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ラ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_リ_sp"
                        data-kana="リ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['リ'] ? ' opened' : '') + (!lecturerList['リ'] ? ' disabled' : '')}
                        data-open="lecturerList-リ"
                    >リ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-リ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['リ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['リ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ル_sp"
                        data-kana="ル"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ル'] ? ' opened' : '') + (!lecturerList['ル'] ? ' disabled' : '')}
                        data-open="lecturerList-ル"
                    >ル</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ル">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ル'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ル']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_レ_sp"
                        data-kana="レ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['レ'] ? ' opened' : '') + (!lecturerList['レ'] ? ' disabled' : '')}
                        data-open="lecturerList-レ"
                    >レ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-レ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['レ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['レ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ロ_sp"
                        data-kana="ロ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ロ'] ? ' opened' : '') + (!lecturerList['ロ'] ? ' disabled' : '')}
                        data-open="lecturerList-ロ"
                    >ロ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ロ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ロ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ロ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                    </div>
            </li>
            <li className="kanaList__item">
                <div className={'kanaList__row icon-open' + (selectedKana['ワ'] ? ' opened' : '') + getDisabled(['ワ'], lecturerList)} onClick={clickKana} data-kana="ワ" id="seminar_main_seminar_lecturer_kana_index_ワ_sp">ワ行</div>
                <div className={'kanaListIndex' + (selectedKana['ワ'] ? ' opened' : '')}>
                    <div
                        id="seminar_main_seminar_lecturer_kana_ワ_sp"
                        data-kana="ワ"
                        onClick={clickKanaChild}
                        className={'kanaList__row detail icon-open' + (selectedKanaChild['ワ'] ? ' opened' : '') + (!lecturerList['ワ'] ? ' disabled' : '')}
                        data-open="lecturerList-ワ"
                    >ワ</div>
                    <div className="lecturerListBoxSp">
                        <div className="lecturerListWrap" id="lecturerListWrap-ワ">
                            <LecturerList
                                className={ 'lecturerList' + (selectedKanaChild['ワ'] ? ' selected-list' : '') }
                                lecturerList={lecturerList['ワ']}
                                selectLecturer={selectLecturer}
                                device="sp"
                            />
                        </div>
                    </div>
                </div>
            </li>
       </ul>
    );
}