import React, { useRef, useState, useEffect } from "react";
import { InputSet } from "./components/InputSet";
import { CheckboxGroup } from "./components/CheckboxGroup";
import { CheckboxGroupPref } from "./components/CheckboxGroupPref";
import { SeminarRow } from "./components/SeminarRow";
import { Kana } from "./components/Kana";
import { KanaSp } from "./components/KanaSp";
import { LecturerList } from "./components/LecturerList";
import * as func from "./components/functions";
import DocumentMeta from 'react-document-meta';

function App()
{
  const [seminarList, setSeminarList] = useState({});
  const [seminarCount, setSeminarCount] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [lecturerList, setLecturerList] = useState({});
  const [keywordValue, setKeywordValue] = useState('');
  const [currentLecturer, setCurrentLecturer] = useState('');
  const [currentModal, setCurrentModal] = useState('');
  const [selectedPrefText, setSelectedPrefText] = useState('');
  const [selectedJobText, setSelectedJobText] = useState('');
  const [selectedDayText, setSelectedDayText] = useState('');
  const [selectedCategoryText, setSelectedCategoryText] = useState('');
  const [selectedLecturerText, setSelectedLecturerText] = useState('');
  const [selectedKana, setSelectedKana] = useState({});
  const [selectedKanaChild, setSelectedKanaChild] = useState({});
  const [currentKanaChild, setCurrentKanaChild] = useState('');
  const [isSideOpen, setIsSideOpen] = useState(false);
  const [isSimpleView, setIsSimpleView] = useState(false);
  const [isRowSimpleView, setIsRowSimpleView] = useState({});
  const [sideInteracted, setSideInteracted] = useState(false);
  const [fetchedSeminarList, setFetchedSeminarList] = useState({});
  const containerRef = useRef(null);
  const resetRef = useRef(null);
  const anchorRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [meta, setMeta] = useState({});
  const [isGoTop, setIsGoTop] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  let timeout;

  useEffect(() => {
    if (keywordValue.trim().length > 1 && isFocused) {
      timeout = setTimeout(() => {
        fetchSuggestionList(keywordValue);
        // const newSuggestionList = fetchSuggestionList(keywordValue);
        // setSuggestionList(newSuggestionList);
      }, 1000);
    } else {
      setSuggestionList([]);
    }

    return () => clearTimeout(timeout);
  }, [keywordValue, isFocused]);

  const keywordChange = (e) => {
    setKeywordValue(e.target.value);
  };

  const clickSuggestion = (suggestionData) => {
    setKeywordValue(suggestionData);
    setSuggestionList([]);
    execSubmit(suggestionData);
  };

  const keywordBlur = () => {
    // setSuggestionList([]);
    setTimeout(() => {
      setIsFocused(false);
      setSuggestionList([]);
    }, 1000); // クリックが発生する前に一瞬待機する
  };
  
  const keywordFocus = () => {
    setIsFocused(true);
  };

  const fetchSuggestionList = (keywordValue) => {
    const suggestUrl = "https://seminar2.dental-plaza.com/api/suggest.php?k=";
    fetch(suggestUrl+keywordValue)
    .then(response => response.json())
    .then(suggestionList => {
      setSuggestionList(suggestionList);
    });
  }

  const [isCheckAll, setIsCheckAll] = useState({
    pref: false,
    job: false,
    day: false,
    category: false,
  });
  const [isCheck, setIsCheck] = useState({
    pref: [],
    job: [],
    day: [],
    group: [],
    genre: [],
  });
  const [inputList, setInputList] = useState({});
  const history_title_before = '【モリタ】';
  const history_title_after = 'セミナー情報 | 歯科情報ポータルサイト デンタルプラザ';

  const setObj = {};
  setObj.pref = (val) => setSelectedPrefText(val);
  setObj.job = (val) => setSelectedJobText(val);
  setObj.day = (val) => setSelectedDayText(val);
  setObj.category = (val) => setSelectedCategoryText(val);

  const fetchSeminarList = (paramsStr = '') =>
  {
    const searchUrl = func.getSearchUrl(fetchedSeminarList, paramsStr, maxPage);

    fetch(searchUrl)
    .then(response => response.json())
    .then(loadedSeminarList => {

      const newSeminarList = Object.assign({}, seminarList);
      const newIsRowSimpleView = Object.assign({}, isRowSimpleView);
      let seminarKey = '';
      Object.keys(loadedSeminarList).forEach(function(key){
        seminarKey = loadedSeminarList[key].NENDO+loadedSeminarList[key].SEMINA_CD+loadedSeminarList[key].SEMINA_IDX;
        newSeminarList[seminarKey] = loadedSeminarList[key];
        newIsRowSimpleView[seminarKey] = isSimpleView;
      });
      
      setSeminarList(newSeminarList);
      const newFetchedSeminarList = Object.assign({}, fetchedSeminarList);
      newFetchedSeminarList[searchUrl] = searchUrl;
      setFetchedSeminarList(newFetchedSeminarList);
      setIsRowSimpleView(newIsRowSimpleView);
      if(Object.keys(fetchedSeminarList).length===maxPage-1){
        setShowLoader(false);
      }
      
    });

  }

  const fetchSeminarCount = (paramsStr = '') =>
  {
    const countUrl = "https://seminar2.dental-plaza.com/api/count.php";
    fetch(countUrl+paramsStr)
    .then(response => response.json())
    .then(loadedSeminarCount => {
      if(loadedSeminarCount.count===0){
        setShowLoader(false);
      }
      setSeminarCount(loadedSeminarCount.count);
      setMaxPage(loadedSeminarCount.maxPage);
    });
  }

  let setPosition = 0;
  const scrollToggleSidebar = () =>
  {
    const pos = 720;
    if(window.scrollY > pos){//サイト下部
      if (setPosition > document.documentElement.scrollTop) {//上にスクロール
        setIsSideOpen(true);
      }else{//下にスクロール
        setIsSideOpen(false);
      }
    }else{//サイト上部（検索ボックスあり）
      setIsSideOpen(false);
    }
    setPosition = document.documentElement.scrollTop;
  }

  const goTop = () =>
  {
    setIsGoTop(true);
    window.scroll({top: 0, behavior: 'smooth'});
  }
  
  const paramsToStr = (params) =>
  {
    let paramsStr = '';
    let i = 0;
    params.forEach(function(value, key){
      i++;
      if(i===1){
        paramsStr+='?';
      }else{
        paramsStr+='&';
      }
      paramsStr+=key+'='+value;
    });
    return paramsStr;
  }
  
  const currentParams = new URL(window.location.href).searchParams;
 
  const callbackFunction = (entries) => {
    const [ entry ] = entries;
    if(entry.isIntersecting && Object.keys(fetchedSeminarList).length!==maxPage){
      const paramsStr = paramsToStr(currentParams);
      fetchSeminarList(paramsStr);
    }
  }

  const options = {
    root: null,
    rootMargin: "0px",
    threshold:1.0
  }

  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(resetGoTop, options);
    if (resetRef.current){
      observer.observe(resetRef.current)
      observerRefValue = resetRef.current;
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    }
  }, [resetRef, options]);

  const resetGoTop = (entries) => {
    const [ entry ] = entries;
    if(entry.isIntersecting){
      setIsGoTop(false);
    }
  }

  useEffect(() => {
    let observerRefValue = null;
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current){
      observer.observe(containerRef.current)
      observerRefValue = containerRef.current;
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    }
  }, [containerRef, options]);
  
  useEffect(() => {
    if(!sideInteracted && !isGoTop){
      window.addEventListener('scroll', scrollToggleSidebar);
    }
    return () => window.removeEventListener('scroll', scrollToggleSidebar);
  }, [sideInteracted, isGoTop]);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const scrollToResultDelay = async () => {
    await delay(1000);
    func.scrollToResult(anchorRef);
  }
  
  useEffect(() =>
  {
    scrollToResultDelay();
    setShowLoader(true);
    const paramsStr = paramsToStr(currentParams);
    const regex = /(\?|&)(list=1)/;
    if(paramsStr.match(regex)){
      setIsSimpleView(true);
    }
    fetchSeminarCount(paramsStr);
    fetchLecturerList();
    //set input from url query string
    const paramsObj = func.paramsToObj(currentParams);
    setIsCheck({
        pref: paramsObj.pref,
        job: paramsObj.job,  
        day: paramsObj.day,
        group: paramsObj.group,
        genre: paramsObj.genre,
    });
    setCurrentLecturer(paramsObj.lecturer);
    setKeywordValue(paramsObj.k);
    //set day checkbox
    func.setDayData(InputSet);
    //set genre checkbox
    setGenreDataThenSetTextAndTitle(InputSet, paramsObj, paramsObj.lecturer);
    setInputList(InputSet);

    window.history.pushState(null, null, null);
    window.addEventListener("popstate", function (e) {
      window.history.pushState(null, null, null);
      return;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleValueObj = (paramsObjArg, textValue, lecturerValue) =>
  {
    meta.title = history_title_before+history_title_after;  
    let singleValue = '';
    let singleValueKey = '';
    
    let checkNum = 0;
    let checkStr = '';
    Object.keys(isCheck).forEach(key => {//paramsObjArgはk,lecturerが入る場合があるのでisCheckでloop
      if(!paramsObjArg[key].length){
        return;
      }
      checkNum+=paramsObjArg[key].length;
      singleValueKey = key;
      singleValue = paramsObjArg[key][0];
      if(checkStr){
        checkStr+= '&';
      }
      checkStr+= key+'='+paramsObjArg[key].join('&');
    });
    
    if((checkNum===1 || checkStr==='pref='+prefValueWeb+'&group='+groupValueWeb)
    && !textValue
    && !lecturerValue){
      return {
        singleValueKey: singleValueKey,
        singleValue: singleValue,
      }
    }
    return {};
  }

  const setTitle = (singleValueKey, singleValue) =>
  {
    if(!singleValueKey){
      meta.title = history_title_before+history_title_after;
    }else{
      const singleTitleValue = InputSet[singleValueKey].rows[singleValueKey+'_'+singleValue].label+' ';
      meta.title = history_title_before+singleTitleValue+history_title_after;
    }
    setMeta(meta);  
  }


  const setGenreDataThenSetTextAndTitle = (InputSet, paramsObj, currentLecturer) =>
  {
      const genreUrl = "https://seminar2.dental-plaza.com/api/category.php";
      fetch(genreUrl)
      .then(response => response.json())
      .then(genreList => {
        genreList.forEach(value => {
          InputSet.genre.rows['genre_'+value.KATEGORI_CD] = {
            name: "genre[]",
            value: value.KATEGORI_CD,
            label: value.KATEGORI_NM,
            idValue: value.KATEGORI_CD,
          };
        });
        setSelectedText(paramsObj, InputSet, currentLecturer);
        const {singleValueKey, singleValue} = getSingleValueObj(paramsObj, paramsObj.k, paramsObj.lecturer);
        setTitle(singleValueKey, singleValue);

      });
  }

  const prefValueWeb = '07';
  const groupValueWeb = '4';

  const clickCheckAll = e =>
  {
    const { dataset, checked } = e.target;
    const check_names = dataset.check_names.split(' ');
    const check_class = dataset.check_class;
    const newIsCheckAll = func.resetIsCheckAllClass(check_class, isCheckAll);
    setIsCheckAll(newIsCheckAll);
    const newIsCheck = Object.assign({}, isCheck);
    if(!checked){
      check_names.forEach(check_name => {
        newIsCheck[check_name] = [];
      });
      //イレギュラー処理
      if(check_class==='category'){
        //WEB以外にチェック
        newIsCheck['pref'] = newIsCheck['pref'].filter(prefValue => prefValue!==prefValueWeb);
      }
      //
    }else{
      check_names.forEach(check_name => {
        Object.keys(inputList[check_name].rows).forEach(key => {
          if(newIsCheck[check_name].includes(inputList[check_name].rows[key].value)){
            return;
          }
          newIsCheck[check_name].push(inputList[check_name].rows[key].value);
        });
      });
      //イレギュラー処理
      if(check_class==='category'){
        //WEBにチェック
        newIsCheck['pref'] = [prefValueWeb];
      }
    }
    setIsCheck(newIsCheck);
  }

  const getNewIsCheck = e =>
  {
    const { value, checked, dataset } = e.target;
    const check_class = dataset.check_class;
    const check_name = dataset.check_name;
    const newIsCheck = Object.assign({}, isCheck);
    if(checked) {
      newIsCheck[check_name] = [...isCheck[check_name], value];
    }else{
      newIsCheck[check_name] = isCheck[check_name].filter(item => item !== value);
      isCheckAll[check_class] = false;
    }
    return newIsCheck;
  }

  const clickCheckbox = e =>
  {
    const newIsCheck = getNewIsCheck(e);
    setIsCheck(newIsCheck);
  }

  //イレギュラー処理
  const clickCheckboxPref = e =>
  {
    const newIsCheck = getNewIsCheck(e);
    const { checked } = e.target;
    newIsCheck['pref'] = newIsCheck['pref'].filter(prefValue => prefValue!==prefValueWeb);
    newIsCheck['group'] = newIsCheck['group'].filter(groupValue => groupValue!==groupValueWeb);
    if(checked){
      isCheckAll.category = false;
      setIsCheckAll(isCheckAll);
    }
    setIsCheck(newIsCheck);
  }

  //イレギュラー処理
  const clickCheckboxPrefWeb = e =>
  {
    const newIsCheck = getNewIsCheck(e);
    const { checked } = e.target;
    if(checked){
      newIsCheck['pref'] = [prefValueWeb];
      if(!newIsCheck['group'].includes(groupValueWeb)){
        newIsCheck['group'].push(groupValueWeb);
      }
    }else{
      newIsCheck['group'] = newIsCheck['group'].filter(groupValue => groupValue!==groupValueWeb);
      isCheckAll.category = false;
      setIsCheckAll(isCheckAll);
    }  
    setIsCheck(newIsCheck);
  }

  //イレギュラー処理
  const clickCheckboxGroup = e =>
  {
    const newIsCheck = getNewIsCheck(e);
    const { value, checked } = e.target;
    if(value===groupValueWeb){//WEB
      if(checked){
        newIsCheck['pref'] = [prefValueWeb];
      }else{
        newIsCheck['pref'] = newIsCheck['pref'].filter(prefValue => prefValue!==prefValueWeb);
      }  
    }
    setIsCheck(newIsCheck);
  }

  const isWeb = (check_name, value) =>
  {
    if((check_name==='pref' && value===prefValueWeb)
    || (check_name==='group' && value===groupValueWeb)
    ){
      return true;
    }
    return false;
  }

  const clickLabel = e =>
  {
    const { dataset } = e.target;
    resetIsCheckAll();

    let newIsCheck = {};
    //イレギュラー処理
    if(isWeb(dataset.check_name, dataset.value)){//地域またはカテゴリ＝WEB
      newIsCheck = {
        pref: [prefValueWeb],
        job: [],
        day: [],
        group: [groupValueWeb],
        genre: [],
      }
      setSelectedPrefText(inputList.pref.rows['pref_'+prefValueWeb].label);
      setSelectedJobText('');
      setSelectedDayText('');
      setSelectedCategoryText(inputList.group.rows['group_'+groupValueWeb].label);  
    }else{//通常処理
      newIsCheck = {
        pref: [],
        job: [],
        day: [],
        group: [],
        genre: [],
      }
      newIsCheck[dataset.check_name].push(dataset.value);
      setSelectedSingleLabel(dataset);
    }
    setIsCheck(newIsCheck);

    setSelectedLecturerText('');
    setKeywordValue('');

    resetSingleVlues();

    let paramsStr = '';
    if((dataset.name==='pref[]' && dataset.value===prefValueWeb)
    || (dataset.name==='group[]' && dataset.value===groupValueWeb)){
        paramsStr = '?pref[]='+prefValueWeb+'&group[]='+groupValueWeb;
    }else{
      paramsStr = '?'+dataset.name+'='+dataset.value;
    }
    if(isSimpleView){
      paramsStr+= '&list=1';
    }
    window.history.replaceState(null, meta.title, paramsStr);
    setTitle(dataset.check_name, dataset.value);
    submitProcess(paramsStr);
  }

  const setSelectedSingleLabel = (dataset) =>
  {
    let selectedLabel = '';
    Object.keys(setObj).forEach(objectName => {
      const checkBoxObj = inputList[dataset.check_name];
      if(objectName===checkBoxObj.check_class){
        selectedLabel = checkBoxObj.rows[checkBoxObj.name+'_'+dataset.value].label;
      }else{
        selectedLabel = '';
      }
      setObj[objectName](selectedLabel);
    });
}

  const fetchLecturerList = () =>
  {
    const lecturerUrl = "https://seminar2.dental-plaza.com/api/lecturerAll.php";
    fetch(lecturerUrl)
    .then(response => response.json())
    .then(lecturerListJson => {
        const newLecturerList = {};
        if(lecturerListJson){
          lecturerListJson.forEach( value => {
            const kana = func.getKana(value.KOUSHI_KANA);
            if(currentLecturer===value.KOUSHI_NM){
              value.className = ' index';
            }else{
              value.className = '';
            }
            if(typeof newLecturerList[kana]==='undefined'){
              newLecturerList[kana] = [];
            }
            newLecturerList[kana].push(value);
          });
        }
        setLecturerList(newLecturerList);
    });
  }

  const clickKana = (e) =>
  {
      const kana = e.target.dataset.kana;
      const newSelectedKana = Object.assign({}, selectedKana);
      if(selectedKana[kana]){
        newSelectedKana[kana] = false;
      }else{
        newSelectedKana[kana] = true;
      }
      setSelectedKana(newSelectedKana);
  }

  const clickKanaChild = (e) =>
  {
    const kana = e.target.dataset.kana;
    setCurrentKanaChild(kana);
    let newSelectedKanaChild = Object.assign({}, selectedKanaChild);
    if(selectedKanaChild[kana]){
      newSelectedKanaChild[kana] = false;
    }else{
      newSelectedKanaChild[kana] = true;
    }    
    setSelectedKanaChild(newSelectedKanaChild);
  }



  const selectLecturer = e =>
  {
    e.preventDefault();
    const koushi_nm = e.target.dataset.koushi_nm;
    setCurrentLecturer(koushi_nm);
    setSelectedText(isCheck, inputList, koushi_nm);
    const paramsStr = valueToParamsStr(koushi_nm, isSimpleView, keywordValue);
    window.history.replaceState(null, meta.title, paramsStr)
    meta.title = history_title_before+history_title_after;
    setMeta(meta);
    submitProcess(paramsStr);
  }

  const valueToParamsStr = (currentLecturer, isSimpleView, keywordValue) =>
  {
    //checkbox
    let params = func.getCheckboxParams(isCheck);
    //text
    if(keywordValue){
      params.push('k='+keywordValue);
    }
    if(currentLecturer){
      params.push('lecturer='+currentLecturer);
    }
    if(isSimpleView){
      params.push('list=1');
    }
    let paramsStr = '/';
    if(params.length){
      paramsStr = '?'+params.join('&');
    }
    return paramsStr;
  }

  const setSelectedText = (checkValues, inputListObj, currentLecturer) =>
  {
    //Pref
    let currentPref = [];
    checkValues.pref.forEach(value => {
      currentPref.push(inputListObj.pref.rows['pref_'+value].label);
    });
    const currentPrefText = currentPref.join(' ');
    setSelectedPrefText(currentPrefText);
    //Job
    let currentJob = [];
    checkValues.job.forEach(value => {
      currentJob.push(inputListObj.job.rows['job_'+value].label);
    });
    const currentJobText = currentJob.join(' ');
    setSelectedJobText(currentJobText);
    //Day
    let currentDay = [];
    checkValues.day.forEach(value => {
      currentDay.push(inputListObj.day.rows['day_'+value].label);
    });
    const currentDayText = currentDay.join(' ');
    setSelectedDayText(currentDayText);
    //Category
    let currentCategory = [];
    checkValues.group.forEach(value => {
      currentCategory.push(inputListObj.group.rows['group_'+value].label);
    });
    checkValues.genre.forEach(value => {
      currentCategory.push(inputListObj.genre.rows['genre_'+value].label);
    });
    const currentCategoryText = currentCategory.join(' ');
    setSelectedCategoryText(currentCategoryText);
    //Lecturer
    if(currentLecturer){
      setSelectedLecturerText(currentLecturer);
    }else{
      setSelectedLecturerText('');
    }
  }

  const submitProcess = (paramsStr) =>
  {
    fetchSeminarCount(paramsStr);
    setFetchedSeminarList({});
    setSeminarList({});
    setShowLoader(true);
    scrollToResultDelay();
    setCurrentModal('');
  }

  const execSubmit = (keywordValue) =>
  {
    setSelectedText(isCheck, inputList, currentLecturer);
    const paramsStr = valueToParamsStr(currentLecturer, isSimpleView, keywordValue);
    window.history.replaceState(null, meta.title, paramsStr);
    const {singleValueKey, singleValue} = getSingleValueObj(isCheck, keywordValue, currentLecturer);
    setTitle(singleValueKey, singleValue);
    submitProcess(paramsStr);
  }
  
  const clickSubmit = () =>
  {
    execSubmit(keywordValue);
  }

  const resetSingleVlues = () =>
  {
    setKeywordValue('');
    setCurrentLecturer('');
    setSelectedLecturerText('');
  }

  const resetSelectedVlues = () =>
  {
    setSelectedPrefText('');
    setSelectedJobText('');
    setSelectedDayText('');
    setSelectedCategoryText('');
    setSelectedLecturerText('');
  }

  const clickReset = () => {
    resetIsCheckAll();
    resetIsCheck();
    resetSingleVlues();
    resetSelectedVlues();
  }

  const openModal = (e) =>
  {
      setCurrentModal(e.target.dataset.open);
  };

  const closeModal = (e) =>
  {
      setCurrentModal('');
  };

  const outsideCloseModal = (e) =>
  {
    if(!e.target.closest('.modal')) {
        setCurrentModal('');
    }
  };

  const resetIsCheckAll = () =>
  {
    setIsCheckAll({
      pref: false,
      job: false,
      day: false,
      category: false,
    });
  }

  const resetIsCheck = () =>
  {
    setIsCheck({
      pref: [],
      job: [],
      day: [],
      group: [],
      genre: [],
    });
  }

  const toggleSidebar = () =>
  {
    //一度でも操作したらスクロールイベントを停止
    setSideInteracted(true);
    if(isSideOpen){
      setIsSideOpen(false);
    }else{
      setIsSideOpen(true);
    }
  }

  const toggleSimpleView = () =>
  {
    let newIsSimpleView;
    if(isSimpleView){
      newIsSimpleView = false;
    }else{
      newIsSimpleView = true;
    }
    const paramsStr = valueToParamsStr(currentLecturer, newIsSimpleView, keywordValue);
    window.history.replaceState(null, meta.title, paramsStr);
    setIsSimpleView(newIsSimpleView);

    const newIsRowSimpleView = {}
    Object.keys(seminarList).forEach(function(key){
      newIsRowSimpleView[seminarList[key].NENDO+seminarList[key].SEMINA_CD+seminarList[key].SEMINA_IDX] = newIsSimpleView;
    });
    setIsRowSimpleView(newIsRowSimpleView);
  }

  const showDetailView = (e) =>
  {
    const key = e.target.dataset.key;
    const newIsRowSimpleView = Object.assign({}, isRowSimpleView);
    newIsRowSimpleView[key] = false;
    setIsRowSimpleView(newIsRowSimpleView);
  }

  return (
    <>
      <DocumentMeta {...meta} />
      <div ref={resetRef}></div>
      <div className="p-SeminarSearch hideWhenPrint">
        <div className="p-SeminarSearchHead">
          <a href="https://seminar3.dental-plaza.com/" className="p-SeminarSearchHead__link" id="seminar_list_all">今年度以降のセミナー一覧</a>
          <a href="https://www6.dental-plaza.com/RyoshushoHakkoSystem/RyoshushoHakkoIchiran/RyoshushoHakkoIchiran" target="_blank" className="p-SeminarSearchHead__link" id="receipt_download">領収書ダウンロード</a>
          <a href="https://www.dental-plaza.com/login/seminar_past.php" className="p-SeminarSearchHead__link" id="seminar_past_app">開催済の申込セミナー</a>
          <a href="https://www.dental-plaza.com/login/seminar.php" className="p-SeminarSearchHead__link" id="seminar_current_app">受講予定の申込セミナー</a>
        </div>
        <div className="p-SeminarSearch__row">
          {inputList.pref && 
            <div className="flexSpNoWrap">
              <div className="p-SeminarSearch__title">{inputList.pref.title}</div>
              <div className="flexWrap">
                <CheckboxGroupPref
                  checkboxRow={inputList.pref}
                  clickCheckbox={clickCheckboxPref}
                  clickCheckboxPrefWeb={clickCheckboxPrefWeb}
                  isCheck={isCheck}
                  clickLabel={clickLabel}
                  labelClass={'p-SeminarSearch__label'}
                  idPrefix="seminar_main_seminar_area_"
                />
              </div>
            </div>
          }
        </div>
        <div className="p-SeminarSearch__row">
          {inputList.job &&
            <div className="flexSpNoWrap">
              <div className="p-SeminarSearch__title">{inputList.job.title}</div>
              {inputList.job.title && <label className="p-SeminarSearch__label all">
                <input
                  type="checkbox"
                  id="seminar_main_seminar_job_all"
                  name={inputList.job.allCheckName}
                  data-check_names={inputList.job.check_names}
                  data-check_class={inputList.job.check_class}
                  onChange={clickCheckAll}
                  checked={isCheckAll[inputList.job.check_class]}
                />全て
              </label>}
              <div className="flexWrap">
                <CheckboxGroup
                  checkboxRow={inputList.job}
                  clickCheckbox={clickCheckbox}
                  isCheck={isCheck}
                  clickLabel={clickLabel}
                  labelClass={'p-SeminarSearch__label job-label'}
                  idPrefix="seminar_main_seminar_job_"
                />
              </div>
            </div>
          }
        </div>
        <div className="p-SeminarSearch__row">
            <div className="p-SeminarSearch__title keyword">キーワード</div>
            <div className="p-SeminarSearch__keyword">
                <input id="seminar_main_semianr_keyword" type="text" name="k" placeholder="セミナー名など" value={keywordValue} className="p-SeminarSearch__keywordInput" onChange={keywordChange} onBlur={keywordBlur} onFocus={keywordFocus} />
                {(suggestionList && suggestionList.length ? true : false) && <ul id="seminar_main_semianr_suggest_list" className="p-SeminarSearch__suggest">
                  {suggestionList.map(suggestion => {
                      return (
                        <li onClick={() => clickSuggestion(suggestion.DATA)}><span className="p-SeminarSearch__suggestCategory">{suggestion.COLUMN_NAME}</span><span className="p-SeminarSearch__suggestTitle">{suggestion.DATA}</span></li>
                      );
                  })}
                </ul>}
            </div>
        </div>

        <div className="p-SeminarSearch__row other">
            <div className="p-SeminarSearch__title">その他</div>
            <button id="seminar_main_seminar_day_modal" className="openModal" data-open="modal-4" onClick={openModal}>開催月</button>
            <button id="seminar_main_seminar_category_modal" className="openModal" data-open="modal-2" onClick={openModal}>カテゴリ</button>
            <button id="seminar_main_seminar_lecturer_modal_pc" className="openModal pc" data-open="modal-3" onClick={openModal}>講師名</button>
            <button id="seminar_main_seminar_lecturer_modal_sp" className="openModal sp" data-open="modal-3-sp" onClick={openModal}>講師名</button>
            <div className="conditions__list">
                {selectedDayText && <span className="conditions__item">開催月：</span>}
                {selectedDayText}
            </div>
            <div className="conditions__list">
                {selectedCategoryText && <span className="conditions__item">カテゴリ：</span>}
                {selectedCategoryText}
            </div>
            <div className="conditions__list">
                {selectedLecturerText && <span className="conditions__item">講師名：</span>}
                {selectedLecturerText}
            </div>
        </div>
        <div className="p-SeminarSearch__row p-SeminarSearchButton">
            <button id="seminar_main_seminar_search" className="p-SeminarSearchButton__search" onClick={clickSubmit}>検索</button>
            <button id="seminar_main_seminar_clear" className="p-SeminarSearchButton__clear" onClick={clickReset}>クリア</button>
        </div>
        <p className="p-SeminarSearchItem">
            <a id="seminar_main_seminar_guide_1" href="https://www.dental-plaza.com/viewcheck/webinar.html" target="_blank" rel="noopener noreferrer"
                className="p-SeminarSearch__link">お使いのパソコン・スマートフォンのWEBセミナー視聴テストはこちら</a>
        </p>
        <p className="p-SeminarSearchItem">
            <a id="seminar_main_seminar_guide_2" href="https://www4.dental-plaza.com/2023/05/02/1517" target="_blank" rel="noopener noreferrer"
                className="p-SeminarSearch__link">2023年5月8日以降の新型コロナウイルス感染症対応</a>
        </p>
        <p className="p-SeminarSearchItem">
            <a id="seminar_main_seminar_guide_3" href="https://www.dental-plaza.com/paid_member/" target="_blank" rel="noopener noreferrer"
                className="p-SeminarSearch__link">友の会有料会員でお申し込みにお困りの方</a>
        </p>
      </div>
      
      <div className="searchResultAnchor" ref={anchorRef}></div>
      <div className="main-col p-SeminarSearchResult">
        <div className="p-SeminarSearchResultHead hideWhenPrint">
            <h2 className="p-SeminarSearchResultHead__title">検索結果一覧</h2>
            <p className="p-SeminarSearchResultHead__text">
                該当するセミナーが<span className="p-SeminarSearchResultHead__count">{seminarCount}</span>件見つかりました。
            </p>
        </div>

		<p className="how2dl hideWhenPrint"><a href="https://www.dental-plaza.com/tomo/receipt.html" target="_blank" rel="noreferrer">領収書のダウンロード方法はこちら</a></p>
		<p className="dlPdf hideWhenPrint"><a href="https://seminar.dental-plaza.com/application_form.pdf" target="_blank" rel="noreferrer">セミナーのFAX用申込書はこちら</a><br />（WEBセミナーはご利用不可）</p>
        <div className={"p-SeminarSearchResultDetailview hideWhenPrint "+(Object.keys(seminarList).length===0 ? 'hide' : 'show')}>
            詳細表示
            <div className="p-SeminarSearchResultDetailviewBox">
                <input type="checkbox" id="seminar_main_seminar_toggleDetail" className="p-SeminarSearchResultDetailview__input" checked={(!isSimpleView) ? 'checked' : ''} onChange={toggleSimpleView} />
                <label htmlFor="seminar_main_seminar_toggleDetail" id="seminar_main_seminar_toggleDetailLabel" className={"p-SeminarSearchResultDetailview__item "+(!isSimpleView ? 'checked' : '')}></label>
            </div>
			<figure><a href="https://www.dental-plaza.com/user/login/">有料会員様限定でマイページ掲載の<br className="spOnly" />「セミナーインフォメーション」から<br />来年度セミナーを優先申込頂けます。<br className="spOnly" />ここからログインください。</a></figure>
        </div>

        <div className="p-SeminarList">
            {Object.keys(seminarList).map(key => {
              return (
                <SeminarRow
                  key={seminarList[key].NENDO+seminarList[key].SEMINA_CD+seminarList[key].SEMINA_IDX}
                  seminarRow={seminarList[key]}
                  inputList={inputList}
                  showDetailView={showDetailView}
                  isRowSimpleView={isRowSimpleView}
                />
              );
            })}
            <div
              className={"loader "+(showLoader ? 'show' : 'hide')}
              ref={containerRef}
            ></div>
        </div>
        
      </div>

      <div className={"sidebar hideWhenPrint"+(isSideOpen ? " side-opened" : '')}>
        <aside className="sidebarBox">
          <button id="seminar_sidebar_seminar_toggle" className="sidebar__arrowButton" onClick={toggleSidebar}></button>
          <h4 className="sidebarSearch__headline"/* onClick={toggleSidebar}*/>
              <span className="sidebarSearch__headlineText">セミナー検索</span>
          </h4>
          <div className="sidebarSearchWrap">
            <h5 className="sidebarSearchCloseContent"/* onClick={toggleSidebar}*/>絞り込み条件</h5>
            <div className="sidebarSearch">
              <div className="sidebarSearchContent">
                <h5 className="sidebarSearch__headline02">絞り込み条件1</h5>
                <div className="sidebarSearchBox">
                  {selectedPrefText && <span className="sidebarSearch__item">地域</span>}
                  {selectedPrefText}
                </div>
                <div className="sidebarSearchBox">
                    {selectedJobText && <span className="sidebarSearch__item">職種</span>}
                    {selectedJobText}
                </div>
                <div className="sidebarSearchBox">
                    {selectedDayText && <span className="sidebarSearch__item">開催月</span>}
                    {selectedDayText}
                </div>
                <span id="seminar_sidebar_seminar_area_job_day_modal" className="openModal" onClick={openModal} data-open="modal-1">地域・職種・開催月</span>
              </div>
              <div className="sidebarSearchContent">
                <h5 className="sidebarSearch__headline02">絞り込み条件2</h5>
                <div className="sidebarSearchBox">
                  {selectedCategoryText && <span className="sidebarSearch__item">カテゴリ</span>}
                  {selectedCategoryText}
                </div>
                <span id="seminar_sidebar_seminar_category_modal" className="openModal" onClick={openModal} data-open="modal-2">カテゴリを設定</span>
              </div>
              <div className="sidebarSearchContent">
                <h5 className="sidebarSearch__headline02">絞り込み条件3</h5>
                <div className="sidebarSearchBox">
                  {selectedLecturerText && <span className="sidebarSearch__item">講師</span>}
                  {selectedLecturerText}
                </div>
                <span id="seminar_sidebar_seminar_lecturer_modal" className="openModal" onClick={openModal} data-open="modal-3">講師名で絞り込む</span>
              </div>
              <div className="sidebarSearchContent">
                  <div className="sidebarSearchBox">
                      <span className="sidebarSearch__item">キーワード</span>
                      <div className="sidebarKeywordSearchBox">
                          <input id="seminar_sidebar_seminar_keyword"
                            type="text"
                            name="k"
                            placeholder="セミナー名など"
                            value={keywordValue}
                            onChange={keywordChange}
                            className="sidebarKeywordSearchBox__keywordInput" />
                          <button id="seminar_sidebar_seminar_search" className="sidebarSearchButton" onClick={clickSubmit}>検索</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="sidebarPrintBox">
              <button id="seminar_sidebar_seminar_print" onClick={func.print} className="sidebarPrintButton">
                <span className="sidebarSearch__PrintText">印刷</span>
              </button>
          </div>
          <div className="sidebarGotopBox">
              <button id="seminar_sidebar_seminar_gotop" onClick={goTop} className="sidebarGotopButton">
                <span className="sidebarSearch__GotoText">ページの先頭へ</span>
              </button>
          </div>

        </aside>
      </div>
      <div className={"modalBg "+(currentModal==='modal-1' ? 'show' : 'hide')}
      id="modal-1" onClick={outsideCloseModal}>
        <div className="modalWrap">
          <button id="seminar_modal1_close" className="modal-close" onClick={closeModal}></button>
          <div className="modal">
            <div className="modalWindow">
              <div className="modalHeadline">絞り込み条件1</div>
              <div className="modal__row">
                {inputList.pref && 
                  <>
                    <div className="modalAssistHeadline">{inputList.pref.title}</div>
                    <div className="labelAllBox"></div>
                    <div className="labelItemBox">
                      <CheckboxGroupPref
                        checkboxRow={inputList.pref}
                        clickCheckbox={clickCheckboxPref}
                        clickCheckboxPrefWeb={clickCheckboxPrefWeb}
                        isCheck={isCheck}
                        clickLabel={clickLabel}
                        labelClass={'modal__label'}
                        idPrefix="seminar_sidebar_seminar_area_"
                      />
                    </div>
                  </>
                }
              </div>
              <div className="modal__row job">
                {inputList.job && 
                  <>
                    <div className="modalAssistHeadline">{inputList.job.title}</div>
                    {inputList.job.title && <div className="labelAllBox">
                      <label className="modal__label all">
                          <input
                              type="checkbox"
                              id="seminar_sidebar_seminar_job_all"
                              name={inputList.job.allCheckName}
                              data-check_names={inputList.job.check_names}
                              data-check_class={inputList.job.check_class}
                              onChange={clickCheckAll}
                              checked={isCheckAll[inputList.job.check_class]}
                          />全て
                      </label>
                    </div>}
                    <div className="labelItemBox">
                      <CheckboxGroup
                        checkboxRow={inputList.job}
                        clickCheckbox={clickCheckbox}
                        isCheck={isCheck}
                        clickLabel={clickLabel}
                        labelClass={'modal__label'}
                        idPrefix="seminar_sidebar_seminar_job_"
                      />
                    </div>
                  </>
                }
              </div>
              <div className="modal__row month">
                {inputList.day && 
                  <>
                    <div className="modalAssistHeadline">{inputList.day.title}</div>
                    {inputList.day.title && <div className="labelAllBox">
                      <label className="modal__label all">
                          <input
                              type="checkbox"
                              id="seminar_sidebar_seminar_day_all"
                              name={inputList.day.allCheckName}
                              data-check_names={inputList.day.check_names}
                              data-check_class={inputList.day.check_class}
                              onChange={clickCheckAll}
                              checked={isCheckAll[inputList.day.check_class]}
                          />全て
                      </label>
                    </div>}
                    <div className="labelItemBox">
                      <CheckboxGroup
                        checkboxRow={inputList.day}
                        clickCheckbox={clickCheckbox}
                        isCheck={isCheck}
                        clickLabel={clickLabel}
                        labelClass={'modal__label'}
                        idPrefix="seminar_sidebar_seminar_day_"
                      />
                    </div>
                  </>
                }
              </div>
              <div className="modal__row">
                <button
                  onClick={clickSubmit}
                  className="modalButton"
                  id="seminar_modal1_search">この条件で検索する</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modalBg "+(currentModal==='modal-2' ? 'show' : 'hide')}
        id="modal-2" onClick={outsideCloseModal}>
        <div className="modalWrap">
          <button id="seminar_modal_close_category" className="modal-close" onClick={closeModal}></button>
          <div className="modal">
            <div className="modalWindow">
              <div className="modalHeadline">絞り込み条件2</div>
              <div className="modal__row category">
                {inputList.group && inputList.genre &&
                  <>
                    <div className="modalAssistHeadline">{inputList.group.title}</div>
                    {inputList.group.title && <div className="labelAllBox">
                      <label className="modal__label all">
                          <input
                              type="checkbox"
                              id="seminar_main_seminar_category_all"
                              name={inputList.group.allCheckName}
                              data-check_names={inputList.group.check_names}
                              data-check_class={inputList.group.check_class}
                              onChange={clickCheckAll}
                              checked={isCheckAll[inputList.group.check_class]}
                          />全て
                      </label>
                    </div>}
                    <div className="labelItemBox">
                      <div className="modal__labelCategoryItemBox">
                        <CheckboxGroup
                          checkboxRow={inputList.group}
                          clickCheckbox={clickCheckboxGroup}
                          isCheck={isCheck}
                          clickLabel={clickLabel}
                          labelClass={'modal__label'}
                          idPrefix="seminar_main_seminar_category_"
                        />
                      </div>
                      <div className="modal__labelCategoryItemBox">
                        <CheckboxGroup
                          checkboxRow={inputList.genre}
                          clickCheckbox={clickCheckbox}
                          isCheck={isCheck}
                          clickLabel={clickLabel}
                          labelClass={'modal__label'}
                          idPrefix="seminar_main_seminar_category_genre_"
                        />
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="modal__row">
                <button
                  onClick={clickSubmit}
                  className="modalButton"
                  id="seminar_category_search">この条件で検索する</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modalBg "+(currentModal==='modal-3' ? 'show' : 'hide')} 
        id="modal-3" onClick={outsideCloseModal}>
        <div className="modalWrap">
          <button id="seminar_main_seminar_lecturer_close_pc" className="modal-close" onClick={closeModal}></button>
          <div className="modal">
            <div className="modalWindow">
              <div className="modalHeadline">絞り込み条件3</div>
              <div className="modal__row">
                <div className="kanaBox">
                    <div className="modalAssistHeadline">講師</div>
                    <Kana
                      clickKanaChild={clickKanaChild}
                      currentKanaChild={currentKanaChild}
                      lecturerList={lecturerList}
                    />
                </div>
                <div className="lecturerListBox">
                  <div className="lecturerListWrap" id="lecturerListWrap">
                    <LecturerList
                      className="lecturerList"
                      lecturerList={lecturerList[currentKanaChild]}
                      selectLecturer={selectLecturer}
                      device="pc"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modalBg "+(currentModal==='modal-3-sp' ? 'show' : 'hide')} 
        id="modal-3-sp" onClick={outsideCloseModal}>
        <div className="modalWrap">
          <button id="seminar_main_seminar_lecturer_close_sp" className="modal-close" onClick={closeModal}></button>
          <div className="modal">
            <div className="modalWindow">
              <div className="modalHeadline">絞り込み条件3</div>
              <div className="modal__row">
                <div className="kanaBoxSp">
                    <div className="modalAssistHeadline">講師</div>
                    <KanaSp
                      clickKana={clickKana}
                      clickKanaChild={clickKanaChild}
                      lecturerList={lecturerList}
                      selectLecturer={selectLecturer}
                      selectedKana={selectedKana}
                      selectedKanaChild={selectedKanaChild}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"modalBg "+(currentModal==='modal-4' ? 'show' : 'hide')} 
        id="modal-4" onClick={outsideCloseModal}>
        <div className="modalWrap">
          <button id="seminar_modal_close_day" className="modal-close" onClick={closeModal}></button>
          <div className="modal">
            <div className="modalWindow">
              <div className="modalHeadline">開催月</div>
              <div className="modal__row">
                {inputList.day && 
                  <>
                    <div className="modalAssistHeadline">{inputList.day.title}</div>
                    {inputList.day.title && <div className="labelAllBox">
                      <label className="modal__label all">
                          <input
                              type="checkbox"
                              id="seminar_main_seminar_day_all"
                              name={inputList.day.allCheckName}
                              data-check_names={inputList.day.check_names}
                              data-check_class={inputList.day.check_class}
                              onChange={clickCheckAll}
                              checked={isCheckAll[inputList.day.check_class]}
                          />全て
                      </label>
                    </div>}
                    <div className="labelItemBox">
                      <CheckboxGroup
                        checkboxRow={inputList.day}
                        clickCheckbox={clickCheckbox}
                        isCheck={isCheck}
                        clickLabel={clickLabel}
                        labelClass={'modal__label'}
                        idPrefix="seminar_main_seminar_day_"
                      />
                    </div>
                  </>
                }
              </div>
              <div className="modal__row">
                <button
                  onClick={clickSubmit}
                  className="modalButton"
                  id="seminar_day_search">この条件で検索する</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
