export const CheckboxGroupPref = ({ checkboxRow, clickCheckbox, clickCheckboxPrefWeb, isCheck, clickLabel, labelClass, idPrefix='' }) =>
{
    const prefValueWeb = '07';
    const getGrayOut = (value) =>
    {
        if((value===prefValueWeb && !isCheck['pref'].includes(prefValueWeb) && isCheck['pref'].length>0) 
        || (value!==prefValueWeb && isCheck['pref'].includes(prefValueWeb)) ){
            return true;
        }
        return false;
    }

    const getOnChange = (value) =>
    {
        if(value===prefValueWeb){
            return clickCheckboxPrefWeb;
        }else{
            return clickCheckbox;
        }
    }

    return (
        <>
            {Object.keys(checkboxRow.rows).map(function (key) {
                return (
                    <span className={labelClass} key={checkboxRow.rows[key].name+checkboxRow.rows[key].value}>
                        <input
                            value={checkboxRow.rows[key].value}
                            name={checkboxRow.rows[key].name}
                            data-check_name={checkboxRow.name}
                            data-check_class={checkboxRow.check_class}
                            type="checkbox"
                            onChange={getOnChange(checkboxRow.rows[key].value)}
                            checked={isCheck[checkboxRow.name].includes(checkboxRow.rows[key].value)}
                            className={getGrayOut(checkboxRow.rows[key].value) ? 'grayOut' : ''}
                        />
                        <span
                            id={idPrefix+checkboxRow.rows[key].idValue}
                            onClick={clickLabel}
                            data-name={checkboxRow.rows[key].name}
                            data-value={checkboxRow.rows[key].value}
                            data-check_name={checkboxRow.name}
                            className={getGrayOut(checkboxRow.rows[key].value) ? 'grayOut' : ''}
                        >{checkboxRow.rows[key].label}
                        </span>
                    </span>
                );
            })}
        </>
    );
};