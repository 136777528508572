export const CheckboxGroup = ({ checkboxRow, clickCheckbox, isCheck, clickLabel, labelClass, idPrefix='' }) =>
{
    return (
        <>
            {Object.keys(checkboxRow.rows).map(function (key) {
                return (
                    <span className={labelClass} key={checkboxRow.rows[key].name+checkboxRow.rows[key].value}>
                        <input
                            value={checkboxRow.rows[key].value}
                            name={checkboxRow.rows[key].name}
                            data-check_name={checkboxRow.name}
                            data-check_class={checkboxRow.check_class}
                            type="checkbox"
                            onChange={clickCheckbox}
                            checked={isCheck[checkboxRow.name].includes(checkboxRow.rows[key].value)}
                        />
                        <span
                            id={idPrefix+checkboxRow.rows[key].idValue}
                            onClick={clickLabel}
                            data-name={checkboxRow.rows[key].name}
                            data-value={checkboxRow.rows[key].value}
                            data-check_name={checkboxRow.name}
                        >{checkboxRow.rows[key].label}
                        </span>
                    </span>
                );
            })}
        </>
    );
};