export const InputSet = {
  // rowsのキーは数字だけだと自動でsortされてしまうのでStringになるようname+valueとする
  pref: {
    id: 1,
    name: 'pref',
    title: "地域",
    allCheckName: "allarea",
    check_names: "pref",
    check_class: "pref",
    rows: {
      "pref_07": { name: "pref[]", value: "07", label: "WEB", idValue: "web" },
      "pref_01": { name: "pref[]", value: "01", label: "北海道 東北", idValue: "hokkaido" },
      "pref_02": { name: "pref[]", value: "02", label: "関東 甲信越", idValue: "kanto" },
      "pref_03": { name: "pref[]", value: "03", label: "東海", idValue: "tokai" },
      "pref_04": { name: "pref[]", value: "04", label: "近畿 北陸", idValue: "kinki" },
      "pref_05": { name: "pref[]", value: "05", label: "中四国", idValue: "chushikoku" },
      "pref_06": { name: "pref[]", value: "06", label: "九州 沖縄", idValue: "kyushu" },
    },
  },
  job: {
    id: 2,
    name: 'job',
    title: "職種",
    allCheckName: "alljob",
    check_names: "job",
    check_class: "job",
    rows:{
      "job_1": { name: "job[]", value: "1", label: "歯科医師", idValue: "dr" },
      "job_2": { name: "job[]", value: "2", label: "歯科衛生士", idValue: "dh" },
      "job_3": { name: "job[]", value: "3", label: "歯科技工士", idValue: "dt" },
      "job_4": { name: "job[]", value: "4", label: "歯科助手", idValue: "da" },
    },
  },
  day: {
    id: 3,
    name: 'day',
    title: "開催月",
    allCheckName: "alldays",
    check_names: "day",
    check_class: "day",
    rows: {},
  },
  group: {
    id: 4,
    name: 'group',
    title: "カテゴリ",
    allCheckName: "allcategory",
    check_names: "group genre",
    check_class: "category",
    rows:{
      "group_4": { name: "group[]", value: "4", label: "WEBセミナー", idValue: "web" },
      "group_1": { name: "group[]", value: "1", label: "講演会", idValue: "lecture" },
      "group_2": { name: "group[]", value: "2", label: "研修会", idValue: "workshop" },
      "group_3": { name: "group[]", value: "3", label: "イベント", idValue: "event" },
    },
  },
  genre: {
    id: 5,
    name: 'genre',
    title: "",
    allCheckName: "",
    check_names: "group genre",
    check_class: "category",
    rows: {},
  },
};