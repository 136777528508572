export const isSingleValue = (key) =>
{
    return key==='k' || key==='lecturer';
}

//URL parameter >> input
export const paramsToObj = (params) =>
{
    const paramsObj = {
        pref: [],
        job: [],
        day: [],
        group: [],
        genre: [],
        lecturer: '',
        k: '',
    };
    
    params.forEach(function(value, key){
        key = key.replace('[]', '');
        if(typeof paramsObj[key]==='undefined'){
            return;
        }
        if(isSingleValue(key)){
            paramsObj[key] = value;
        }else{
            paramsObj[key].push(value);
        }
    });
    return paramsObj;
}

export const print = () =>
{
    window.print();
}

export const scrollToResult = (anchorRef) =>
{
  if(anchorRef && anchorRef.current) {
    anchorRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }
}

export const setDayData = (InputSet) =>
{
    //開催月チェックボックス
    const d = new Date();
    const months = 12;
    for(let m=0;m<months;m++){
      let dd = new Date(d.getFullYear(), d.getMonth()+m, 1);
      let value = dd.getFullYear().toString()+(dd.getMonth()+1).toString().padStart(2, '0');
      InputSet.day.rows['day_'+value] = {
        name: "day[]",
        value: value,
        label: `${dd.getFullYear().toString()}年${(dd.getMonth()+1).toString().padStart(2, '0')}月`,
        idValue: value,
      };
    }
}

export const resetIsCheckAllClass = (check_class, isCheckAll) =>
{
  const newIsCheckAll = Object.assign({}, isCheckAll);
  newIsCheckAll[check_class] = !isCheckAll[check_class];
  return newIsCheckAll;
}

export const getCheckboxParams = (isCheck) =>
{
  let params = [];
  Object.keys(isCheck).forEach(function (key) {
    if(!isSingleValue(key)){
      isCheck[key].forEach(function (value) {
        params.push(key+'[]='+value);
      });
    }
  });
  return params;
}

export const getSearchUrl = (fetchedSeminarList, paramsStr) =>
{
  let searchUrl = "https://seminar2.dental-plaza.com/api/search.php";
  if(Object.keys(fetchedSeminarList).length===0){
    return searchUrl+paramsStr;
  }
  if(paramsStr){
    paramsStr += '&page=';
  }else{
    paramsStr += '?page=';
  }
  return searchUrl+paramsStr+(Object.keys(fetchedSeminarList).length+1).toString();
}

export const getKana = (KOUSHI_KANA) =>
{
  const kanaMap = {
    'ヴ': 'ウ',
    'ガ': 'カ',
    'ギ': 'キ',
    'グ': 'ク',
    'ゲ': 'ケ',
    'ゴ': 'コ',

    'ザ': 'サ',
    'ジ': 'シ',
    'ズ': 'ス',
    'ゼ': 'セ',
    'ゾ': 'ソ',

    'ダ': 'タ',
    'ヂ': 'チ',
    'ヅ': 'ツ',
    'デ': 'テ',
    'ド': 'ト',

    'バ': 'ハ',
    'ビ': 'ヒ',
    'ブ': 'フ',
    'ベ': 'ヘ',
    'ボ': 'ホ',

    'パ': 'ハ',
    'ピ': 'ヒ',
    'プ': 'フ',
    'ペ': 'ヘ',
    'ポ': 'ホ',
  };
  let kana = KOUSHI_KANA.slice(0, 1);
  if(typeof kanaMap[kana]!=='undefined'){
    kana = kanaMap[kana];
  }
  return kana;
}