import React from "react";
export const SeminarRow = ({ seminarRow, inputList, showDetailView, isRowSimpleView }) =>
{
    const seminarKeys = seminarRow.NENDO+seminarRow.SEMINA_CD+seminarRow.SEMINA_IDX;
    const fileUrl = 'https://seminar2.dental-plaza.com/img/'+seminarKeys+'/';
    const seminarDateKeys = [1, 2, 3, 4, 5];
    let startDateYear = '';

    const getMonthDayWeekFormat = (date) =>
    {
        if(!date){
            return '';
        }
        const y = date.substr(0, 4);
        const m = date.substr(5, 2);
        const d = date.substr(8, 2);
        const dateObj = new Date( y, parseInt(m)-1 , d );
        const DayOfWeekList = [ "日", "月", "火", "水", "木", "金", "土" ];
        return m+'月'+d+'日 ('+DayOfWeekList[dateObj.getDay()]+')';
    }

    const getStatus = () =>
    {
        if(seminarRow.ZANSEKI_JOKYO==='1'){
            return <span className="situation__item FewRemaining">残りわずか</span>;
        }else
        if(seminarRow.ZANSEKI_JOKYO==='2' && seminarRow.MANINONREI_FLG==='0'){
            return <span className="situation__item WaitingList">キャンセル待ち</span>;
        }else
        if(seminarRow.ZANSEKI_JOKYO==='2' && seminarRow.MANINONREI_FLG==='1'){
            return <span className="situation__item FullHouse">満員御礼</span>;
        }
    }

    const getCategory = () =>
    {
        const genreKeys = [
            'KATEGORI_CD1',
            'KATEGORI_CD2',
            'KATEGORI_CD3',
            'KATEGORI_CD4',
            'KATEGORI_CD5',
            'KATEGORI_CD6',
            'KATEGORI_CD7'
        ];
        const groupRowIdPrefix = 'group_';
        const genreRowIdPrefix = 'genre_';
        const label = typeof inputList.group.rows[groupRowIdPrefix+seminarRow.KATEGORI_KBN]==='undefined' ? '' : inputList.group.rows[groupRowIdPrefix+seminarRow.KATEGORI_KBN].label;
        return <ul className="p-SeminarListCategory">
            <li className="p-SeminarListCategory__item">{label}</li>
            {genreKeys.map(key => {
                if(seminarRow[key] && inputList.genre.rows[genreRowIdPrefix+seminarRow[key]]){
                    return <li key={genreRowIdPrefix+seminarRow[key]} className="p-SeminarListCategory__item">
                        {inputList.genre.rows[genreRowIdPrefix+seminarRow[key]].label}
                    </li>
                }
                return '';
            })}
        </ul>;
    }

    const getImageClass = () =>
    {
        let classStr = 'imageSingle';
        if(seminarRow[`KOUSHI_PATH3`]){
            classStr = 'imageAbove';
        }else
        if(seminarRow[`KOUSHI_PATH2`]){
            classStr = 'imageDouble';
        }
        return classStr;
    }

    const getImageList = () =>
    {
        const html = [];
        let KOUSHI_PATH = '';
        for(let i=1;i<=10;i++){
            KOUSHI_PATH = seminarRow[`KOUSHI_PATH${i}`];
            if(KOUSHI_PATH===''){
                continue;
            }
            html.push(<li key={fileUrl+KOUSHI_PATH} className={"p-SeminarListImgList__item "+getImageClass()}>
                <img src={fileUrl+KOUSHI_PATH} alt={seminarRow[`KOUSHI_NM${i}`]} />
            </li>);
        }
        if(Object.keys(html).length>0){
            return <ul className={"p-SeminarListImgList hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                {html}
            </ul>
        }
    }

    const getLecturerName = (KOUSHI_NM, KEISHO_FLG, TagName='li', className) =>
    {
        if(KOUSHI_NM === ''){
            return '';
        }
        const html = [];
        if(KOUSHI_NM.includes('/') !== false){
            const lecturerList = KOUSHI_NM.replace('/',' ').split(' ');
            lecturerList.forEach((lecturerName) => {
                html.push(<TagName className={className} key={lecturerName}>{lecturerName + (KEISHO_FLG==='1' ? ' 先生' : '')}</TagName>);
            });
        }else{
            html.push(<TagName className={className} key={KOUSHI_NM}>{KOUSHI_NM + (KEISHO_FLG==='1' ? ' 先生' : '')}</TagName>);
        }
        return html;
    }

    const getSeminarDateLabel = () =>
    {
        if(seminarRow.KAISAI_EDT1){
            if(seminarRow.KATEGORI_KBN==='4'){
                return '配信期間';
            }
            return '開催期間';
        }
        return '日時';
    }

    const getSeminarStartTime = (L_KAISAI_SETM_HFLG, KAISAI_STM) =>
    {
        if(L_KAISAI_SETM_HFLG==='1' && ( KAISAI_STM )){
            return ' '+KAISAI_STM.slice(0, 5);
        }
    }

    const getSeminarEndTime = (KAISAI_EDT, KAISAI_ETM) =>
    {
        const seminarEndTime = [];
        if(!KAISAI_EDT && KAISAI_ETM){
            seminarEndTime.push(' 〜 ');
        }
        if(KAISAI_ETM){
            seminarEndTime.push(' '+KAISAI_ETM.slice(0,5));
        }
        return seminarEndTime;
    }

    const dateDisplay = (L_KAISAI_DT_HFLG, L_KAISAI_SETM_HFLG) =>
    {
        if(L_KAISAI_DT_HFLG!=='1' && L_KAISAI_SETM_HFLG!=='1'){
            return false;
        }
        return true;
    }

    const startEndDifference = (KAISAI_DT, KAISAI_EDT) =>
    {
        if(KAISAI_DT!==KAISAI_EDT){
            return true;
        }
        return false;
    }

    const getSeminarStartDate = (key, keyStr, startDateYear, delimiter, indent, KAISAI_DT) =>
    {
        const seminarDateRow = [];
        if(key>1){
            seminarDateRow.push(delimiter);//
        }
        //開始 年
        if(key===1){
            startDateYear = '';
        }
        const startDateYearTmp = KAISAI_DT.slice(0,4);
        if(startDateYear!==startDateYearTmp){
            seminarDateRow.push(<span key={'startYear_'+keyStr}>{startDateYearTmp}年 </span>);
            startDateYear = startDateYearTmp;
        }else{
            seminarDateRow.push(<span key={'startYear_'+keyStr} className={indent ? "dateIndent" : ""}></span>);
        }
        //開始 月日
        seminarDateRow.push(getMonthDayWeekFormat(KAISAI_DT));

        return [seminarDateRow, startDateYear];
    }

    const getFee = () =>
    {
        let fee = '';
        if(seminarRow.MURYO_FLG==='1'){
            fee+= '無料';
        }else
        if(seminarRow.MURYO_FLG==='0'){
            fee+= seminarRow.SANKAHI_NM1;
            fee+= Number(seminarRow.SANKAHI_MNY1).toLocaleString()+'円';
            if (seminarRow.L_SANKAHI_HYWR1_HFLG==='1'){
                fee+= '(WEB早割金額 ' + Number(seminarRow.SANKAHI_HYWR1).toLocaleString() + '円)';
            }
        }
        return fee;
    }

    const getFreeOrFee = () =>
    {
        if(seminarRow.MURYO_FLG==='0'){
            return <span className="p-SeminarListHead_fee paid">有料</span>;
        }else{
            return <span className="p-SeminarListHead_fee">無料</span>;
        }
    }

    const getFeeList = () =>
    {
        const feeList = [];
        let feeListStr = '';
        //参加費名(2～18)
        // 2016/02/29修正 最大参加費数6→18
        let SANKAHI_NM = '';
        //WEB金額
        let SANKAHI_MNY = '';
        //WEB早割金額
        let SANKAHI_HYWR = '';
        //一覧_参加費名表示フラグ
        let L_SANKAHI_NM = '';
        //一覧_WEB金額表示フラグ
        let L_SANKAHI_MNY = '';
        //一覧_WEB金額表示フラグ
        let L_SANKAHI_HYWR = '';
        for(let i=2 ; i<=18 ; i++ ){
            SANKAHI_NM = seminarRow['SANKAHI_NM' + i];
            SANKAHI_MNY = seminarRow['SANKAHI_MNY' + i];
            SANKAHI_HYWR = seminarRow['SANKAHI_HYWR' + i];
            L_SANKAHI_NM = seminarRow['L_SANKAHI_NM' + i + '_HFLG'];
            L_SANKAHI_MNY = seminarRow['L_SANKAHI_MNY' + i + '_HFLG'];
            L_SANKAHI_HYWR = seminarRow['L_SANKAHI_HYWR' + i  + '_HFLG'];
            feeListStr = '';
            //無料セミナーフラグ：1 無料
            if(seminarRow.MURYO_FLG==='0'){
                if (L_SANKAHI_NM==='1'){
                    feeListStr += SANKAHI_NM;
                }
                if (L_SANKAHI_MNY==='1'){
                    if(feeListStr !== ''){
                        feeListStr += ' ';
                    }
                    //WEB金額
                    feeListStr += Number(SANKAHI_MNY).toLocaleString() + '円';
                }
                if(L_SANKAHI_HYWR==='1'){
                    if(feeListStr !== ''){
                        feeListStr += ' ';
                    }
                    //WEB早割金額
                    feeListStr += '(WEB早割金額 ' + Number(SANKAHI_HYWR).toLocaleString() + '円)';
                }
                if(feeListStr !== ''){
                    feeList.push(<React.Fragment key={feeListStr}>{feeListStr}<br /></React.Fragment>);
                }
            }
        }
        return feeList;
    }

    const getApplicationButton = () =>
    {
        const idStr = `seminar_main_seminar_application_${seminarKeys}`;
        if (seminarRow['MOSHIKOMI_HFLG']!=='1' 
        ||  seminarRow['MOSHIKOMI_URL']===""
        //残席状況が2(満員)かつ満員御礼フラグが1(満員御礼)のときは表示しない
        || (seminarRow['ZANSEKI_JOKYO']==='2' && seminarRow['MANINONREI_FLG']==='1')){
            return [];
        }
        //残席状況が2(満員)かつ満員御礼フラグが0(キャンセル待ち)のときはボタンの文言を変更
        if(seminarRow['ZANSEKI_JOKYO']==='2' && seminarRow['MANINONREI_FLG']==='0'){
            return <a href={seminarRow['MOSHIKOMI_URL']} id={idStr} target="_blank" rel="noreferrer" className="p-SeminarListContentButton waitinglist">キャンセル待ち</a>;
        }else{
            return <a href={seminarRow['MOSHIKOMI_URL']} id={idStr} target="_blank" rel="noreferrer" className="p-SeminarListContentButton application">お申込み</a>;
        }
    }

    const getDetailButton = () =>
    {
        const detailUrl ='https://seminar.dental-plaza.com/detail/index.php';
        const idStr = `seminar_main_seminar_detail_show_${seminarKeys}`;
        const detailButton = [];
        if (seminarRow['D_KOKAI_FLG']==='1'){
            if(typeof seminarRow['L_DETAIL_KBN']!=='undefined'){
                if(seminarRow['L_DETAIL_KBN']==='0'){
                    if(seminarRow['L_DETAIL_URL']!==""){
                        //PDF名取得
                        detailButton.push(<a key={fileUrl+seminarRow['L_DETAIL_URL']} id={idStr} href={fileUrl+seminarRow['L_DETAIL_URL']} target="_blank" rel="noreferrer" className="p-SeminarListContentButton detail">セミナー詳細</a>);
                    }
                }else
                if(seminarRow['L_DETAIL_KBN']==='1'){
                    //外部URL取得
                    detailButton.push(<a key={seminarRow['L_DETAIL_URL']} id={idStr} href={seminarRow['L_DETAIL_URL']} target="_blank" rel="noreferrer" className="p-SeminarListContentButton detail">セミナー詳細</a>);
        
                }else
                if(seminarRow['L_DETAIL_KBN']==='2'){
                    //詳細ページにキーを渡す
                    detailButton.push(<a key={detailUrl + "?keys=" + seminarKeys} id={idStr} href={detailUrl + "?keys=" + seminarKeys} target="_blank" rel="noreferrer" className="p-SeminarListContentButton detail">セミナー詳細</a>);
                }
            }
        }else{
            //PDFまたは外部URLの場合は表示させる
            if(typeof seminarRow['L_DETAIL_KBN']!=='undefined'){
                if(seminarRow['L_DETAIL_KBN']==='0'){
                    if(seminarRow['L_DETAIL_URL']!==""){
                        //PDF名取得
                        detailButton.push(<a key={fileUrl+seminarRow['L_DETAIL_URL']} id={idStr} href={fileUrl+seminarRow['L_DETAIL_URL']} target="_blank" rel="noreferrer" className="p-SeminarListContentButton detail">セミナー詳細</a>);
                    }
                }else
                if(seminarRow['L_DETAIL_KBN']==='1'){
                    //外部URL取得
                    detailButton.push(<a key={seminarRow['L_DETAIL_URL']} id={idStr} href={seminarRow['L_DETAIL_URL']} target="_blank" rel="noreferrer" className="p-SeminarListContentButton detail">セミナー詳細</a>);
                }
            }
        }
        return detailButton;
    }

    const nl2br = (str) =>
    {
        var regex = /(\n)/g
        // var regex = /(は)/g
        let n = 0;
        return str.split(regex).map(function (text) {
            n++;
            if (text.match(regex)) {
                // return React.createElement('br')
                return <br key={seminarKeys+str+'_'+n.toString()} />;
            }else{
                return text;
            }
        });
    }

    const getText = (index) =>
    {
        if(seminarRow[`${index}TXT`]){
            return <div className="p-SeminarListText">
                {nl2br(seminarRow[`${index}TXT`])}
            </div>;
        }else
        if(seminarRow[`${index}IMG`]){
            let href = '';
            if(seminarRow[`${index}URL`]){
                href = seminarRow[`${index}URL`];
            }else
            if(seminarRow[`${index}PDF`]){
                href = fileUrl+seminarRow[`${index}PDF`];
            }
            if(href){
                return <div className="p-SeminarListText">
                    <a href={href} target="_blank" rel="noreferrer"><img src={fileUrl+seminarRow[`${index}IMG`]} alt={seminarRow[`${index}ALT`]} /></a>
                </div>;
            }else{
                return <div className="p-SeminarListText">
                    <img src={fileUrl+seminarRow[`${index}IMG`]} alt={seminarRow[`${index}ALT`]} />
                </div>;
            }
        }
    }

    const getApplicationDate = () => 
    {
        let applicationDate = [];
        let applicationStartDateY = '';
        let applicationEndDateY = '';
        if(seminarRow.MOSHIKOMI_DT){
            applicationStartDateY = seminarRow.MOSHIKOMI_DT.slice(0,4);
            applicationDate.push(seminarRow.MOSHIKOMI_DT.slice(0,4)+'年 '+getMonthDayWeekFormat(seminarRow.MOSHIKOMI_DT));
        }
        applicationDate.push(' 〜 ');
        if(seminarRow.MOSHIKOMI_EDT){
            applicationDate.push(<br key={1} />);
            applicationEndDateY = seminarRow.MOSHIKOMI_EDT.slice(0,4);
            if(applicationStartDateY!==applicationEndDateY){
                applicationDate.push(seminarRow.MOSHIKOMI_EDT.slice(0,4)+'年 ');
            }else{
                applicationDate.push(<span key={2} className="dateIndent"></span>);
            }
            applicationDate.push(getMonthDayWeekFormat(seminarRow.MOSHIKOMI_EDT));
        }
        return applicationDate;
    }

    return (
        <div className={"p-SeminarListItem p-SeminarList__detail"+(seminarRow['L_KOUSHI_TATE_FLG']==='1' ? ' solo' : ' full')}>
            <div className="p-SeminarListHead">
                {getFreeOrFee()}
                <span className="p-SeminarListHead_area">{seminarRow.KAISAICHI_NM}</span>
                <span className="p-SeminarListHead_day">
                    {seminarDateKeys.map(key => {
                        if(key>=2 && !dateDisplay(seminarRow[`L_KAISAI_DT${key}_HFLG`], seminarRow[`L_KAISAI_SETM${key}_HFLG`])){
                            return '';
                        }
                        const seminarDateRow = [];
                        const keyStr = key.toString()+'_'+seminarKeys+'_top';
                        const [seminarDateRowTmp, startDateYearTmp] = getSeminarStartDate(key, keyStr, startDateYear, '、', false, seminarRow[`KAISAI_DT${key}`]);
                        startDateYear = startDateYearTmp;
                        seminarDateRow.push(seminarDateRowTmp);
                        if(seminarRow[`KAISAI_EDT${key}`]){
                            if(startEndDifference(seminarRow[`KAISAI_DT${key}`], seminarRow[`KAISAI_EDT${key}`])){
                                seminarDateRow.push(' 〜 ');
                                if(seminarRow[`KAISAI_EDT${key}`].slice(0, 4)!==seminarRow[`KAISAI_DT${key}`].slice(0, 4)){
                                    //終了 年
                                    seminarDateRow.push(seminarRow[`KAISAI_EDT${key}`].slice(0, 4)+'年 ');
                                }
                                //終了 月日
                                seminarDateRow.push(getMonthDayWeekFormat(seminarRow[`KAISAI_EDT${key}`]));
                            }
                        }
                        return seminarDateRow;
                    })}
                </span>
                {getStatus()}
            </div>
            <h3 className="p-SeminarList__title situation">
                {getStatus()}
                {seminarRow.TITLE}
                {seminarRow.L_SUBTITLE_HFLG==='1' && <><br />{seminarRow.SUBTITLE}</>}
                {seminarRow.KAIJO_HENKO_FLG==='1' && <span className="p-SeminarListKaijoHenko">【会場変更】</span>}
            </h3>
            <div className={'hideWhenPrint '+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                <div className="p-SeminarListTop">
                    <ul className="p-SeminarListTarget">
                        <li className={"p-SeminarListTarget__item"+(seminarRow.TAISHO_DR==='1' ? ' active' : '')}>DR</li>
                        <li className={"p-SeminarListTarget__item"+(seminarRow.TAISHO_DH==='1' ? ' active' : '')}>DH</li>
                        <li className={"p-SeminarListTarget__item"+(seminarRow.TAISHO_DT==='1' ? ' active' : '')}>DT</li>
                        <li className={"p-SeminarListTarget__item"+(seminarRow.TAISHO_DA==='1' ? ' active' : '')}>DA</li>
                    </ul>
                    {getCategory()}
                </div>
            </div>
            <div className={"p-SeminarListTextBefore hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                {getText('L_1_1_')}
                <div className="flexWrap">
                    <div className="colSpHalfL">{getText('L_2_1_')}</div>
                    <div className="colSpHalfR">{getText('L_2_2_')}</div>
                </div>
            </div>

            <div className={"p-SeminarListMain"+(isRowSimpleView[seminarKeys] ? ' simpleView' : ' detailView')}>
                {getImageList()}
                <div className="p-SeminarListContentBox">
                    <dl className={"p-SeminarListContent instructor hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                        <dt className="p-SeminarListContent__item">
                            <span className="p-SeminarListContent__text">講師</span>
                        </dt>
                        <dd className="p-SeminarListContent__data">
                            <ul className="instructorList">
                                {getLecturerName(seminarRow.KOUSHI_NM1, seminarRow.KEISHO_FLG1)}
                                {getLecturerName(seminarRow.KOUSHI_NM2, seminarRow.KEISHO_FLG2)}
                                {getLecturerName(seminarRow.KOUSHI_NM3, seminarRow.KEISHO_FLG3)}
                                {getLecturerName(seminarRow.KOUSHI_NM4, seminarRow.KEISHO_FLG4)}
                                {getLecturerName(seminarRow.KOUSHI_NM5, seminarRow.KEISHO_FLG5)}
                                {getLecturerName(seminarRow.KOUSHI_NM6, seminarRow.KEISHO_FLG6)}
                                {getLecturerName(seminarRow.KOUSHI_NM7, seminarRow.KEISHO_FLG7)}
                                {getLecturerName(seminarRow.KOUSHI_NM8, seminarRow.KEISHO_FLG8)}
                                {getLecturerName(seminarRow.KOUSHI_NM9, seminarRow.KEISHO_FLG9)}
                                {getLecturerName(seminarRow.KOUSHI_NM10, seminarRow.KEISHO_FLG10)}
                            </ul>
                        </dd>
                    </dl>
                    <div className={"p-SeminarList__listBox showWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewShow' : 'simpleViewHide')}>
                        <div className="instructorListWrap">
                            <div className="instructorLabelWrap"><span className="instructorLabel">講師</span></div>
                            <span className="instructorList">
                                {getLecturerName(seminarRow.KOUSHI_NM1, seminarRow.KEISHO_FLG1, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM2, seminarRow.KEISHO_FLG2, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM3, seminarRow.KEISHO_FLG3, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM4, seminarRow.KEISHO_FLG4, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM5, seminarRow.KEISHO_FLG5, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM6, seminarRow.KEISHO_FLG6, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM7, seminarRow.KEISHO_FLG7, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM8, seminarRow.KEISHO_FLG8, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM9, seminarRow.KEISHO_FLG9, 'span', 'instructor__item')}
                                {getLecturerName(seminarRow.KOUSHI_NM10, seminarRow.KEISHO_FLG10, 'span', 'instructor__item')}
                            </span>
                        </div>
                    </div>
                    <div className={"hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                        <dl className="p-SeminarListContent schedule">
                            <dt className="p-SeminarListContent__item">
                                <span className="p-SeminarListContent__text">
                                    {getSeminarDateLabel()}
                                </span>
                            </dt>
                            <dd className="p-SeminarListContent__data">
                                {seminarDateKeys.map(key => {
                                    if(key>=2 && !dateDisplay(seminarRow[`L_KAISAI_DT${key}_HFLG`], seminarRow[`L_KAISAI_SETM${key}_HFLG`])){
                                        return '';
                                    }
                                    const seminarDateRow = [];
                                    const keyStr = key.toString()+'_'+seminarKeys+'_detail';
                                    const [seminarDateRowTmp, startDateYearTmp] = getSeminarStartDate(key, keyStr, startDateYear, <br key={'br1_'+keyStr} />, true, seminarRow[`KAISAI_DT${key}`]);
                                    startDateYear = startDateYearTmp;
                                    seminarDateRow.push(seminarDateRowTmp);
                                    if(seminarRow[`L_KAISAI_SETM${key}_HFLG`]==='1'){
                                        //開始 時分
                                        seminarDateRow.push(getSeminarStartTime(seminarRow[`L_KAISAI_SETM${key}_HFLG`], seminarRow[`KAISAI_STM${key}`]));
                                    }
                                    if(seminarRow[`KAISAI_EDT${key}`]){
                                        seminarDateRow.push(' 〜 ');
                                        if(startEndDifference(seminarRow[`KAISAI_DT${key}`], seminarRow[`KAISAI_EDT${key}`])){
                                            seminarDateRow.push(<br key={'br2_'+keyStr} />);
                                            if(seminarRow[`KAISAI_EDT${key}`].slice(0, 4)===seminarRow[`KAISAI_DT${key}`].slice(0, 4)){
                                                seminarDateRow.push(<span key={'indent_'+keyStr} className="dateIndent"></span>);
                                            }else{
                                                //終了 年
                                                seminarDateRow.push(seminarRow[`KAISAI_EDT${key}`].slice(0, 4)+'年 ');
                                            }
                                            //終了 月日
                                            seminarDateRow.push(getMonthDayWeekFormat(seminarRow[`KAISAI_EDT${key}`]));
                                        }
                                    }
                                    if(seminarRow[`L_KAISAI_SETM${key}_HFLG`]==='1'){
                                        //終了 時分
                                        seminarDateRow.push(getSeminarEndTime(seminarRow[`KAISAI_EDT${key}`], seminarRow[`KAISAI_ETM${key}`]));
                                    }
                                    return seminarDateRow;
                                })}
                            </dd>
                        </dl>
                        <dl className="p-SeminarListContent">
                            <dt className="p-SeminarListContent__item">
                                <span className="p-SeminarListContent__text">申込期間</span>
                            </dt>
                            <dd className="p-SeminarListContent__data">
                                {getApplicationDate()}
                                {/* {seminarRow.MOSHIKOMI_DT && getMonthDayWeekFormat(seminarRow.MOSHIKOMI_DT)} 〜 {seminarRow.MOSHIKOMI_EDT && getMonthDayWeekFormat(seminarRow.MOSHIKOMI_EDT)} */}
                            </dd>
                        </dl>
                        {seminarRow.TEIIN_HFLG==='1' &&
                        <dl className="p-SeminarListContent">
                            <dt className="p-SeminarListContent__item">
                                <span className="p-SeminarListContent__text">定員</span>
                            </dt>
                            <dd className="p-SeminarListContent__data">
                                {seminarRow.TEIIN} 名
                            </dd>
                        </dl>}
                        <dl className="p-SeminarListContent">
                            <dt className="p-SeminarListContent__item">
                                <span className="p-SeminarListContent__text">参加費</span>
                            </dt>
                            <dd className="p-SeminarListContent__data">
                                {getFee()}<br />
                                {getFeeList()}
                            </dd>
                        </dl>
                        <dl className="p-SeminarListContent">
                            <dt className="p-SeminarListContent__item">
                                <span className="p-SeminarListContent__text">お問合わせ先</span>
                            </dt>
                            <dd className="p-SeminarListContent__data">
                                {seminarRow.UNEI_NM}
                                <div className="flexNoWrap">
                                    <div className="colAuto pr20">{seminarRow.UNEI_TEL1 ? <><br />T：<a href={"tel:"+seminarRow.UNEI_TEL1}>{seminarRow.UNEI_TEL1}</a></> : '' }</div>
                                    <div className="colAuto">{seminarRow.UNEI_FAX ? <><br />F：{seminarRow.UNEI_FAX}</> : '' }</div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="hideSp">
                    <div className={"p-SeminarListContentButtonBox hideWhenPrint "+(isRowSimpleView[seminarKeys] ? '' : 'w200')}>
                        <ul className="p-SeminarListContentButtonList flexWrap">
                            <li className={"p-SeminarListContentButtonList__item w200 mr10 showWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewShow' : 'simpleViewHide')}>
                                <span id={`seminar_detail_show_${seminarKeys}`} className="p-SeminarListContentButton addition" onClick={showDetailView} data-key={seminarKeys}>追加表示</span>
                            </li>
                            <li className="p-SeminarListContentButtonList__item w200">
                                {getApplicationButton()}
                            </li>
                            <li className={"p-SeminarListContentButtonList__item w200 hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                                {getDetailButton()}
                            </li>
                        </ul>
                    </div>                    
                </div>
            </div>

            <div className={"p-SeminarListTextAfter hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow')}>
                {getText('L_1_2_')}
                <div className="flexWrap">
                    <div className="colSpHalfL">{getText('L_2_3_')}</div>
                    <div className="colSpHalfR">{getText('L_2_4_')}</div>
                </div>
            </div>

            <div className="showSp">
                <div className="p-SeminarListContentButtonBox hideWhenPrint mb30">
                    <ul className="p-SeminarListContentButtonList flexNoWrap">
                        <li className={"p-SeminarListContentButtonList__item hideWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewHide' : 'simpleViewShow colHalf pr5')}>
                            {getDetailButton()}
                        </li>
                        <li className={"p-SeminarListContentButtonList__item showWhenPrint "+(isRowSimpleView[seminarKeys] ? 'simpleViewShow colHalf pr5' : 'simpleViewHide')}>
                            <span id={`seminar_detail_show_${seminarKeys}`} className="p-SeminarListContentButton addition" onClick={showDetailView} data-key={seminarKeys}>追加表示</span>
                        </li>
                        <li className="p-SeminarListContentButtonList__item colHalf pl5">
                            {getApplicationButton()}
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>
    );
};