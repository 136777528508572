export const Kana = ({clickKanaChild, currentKanaChild, lecturerList}) =>
{
    return (
        <ul className="kanaList">
            <li className="kanaList__item">
                <div className="kanaList__row">ア行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ア']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ア_pc" data-kana="ア" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ア' ? ' index' : '')}>ア</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['イ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_イ_pc" data-kana="イ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='イ' ? ' index' : '')}>イ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ウ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ウ_pc" data-kana="ウ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ウ' ? ' index' : '')}>ウ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['エ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_エ_pc" data-kana="エ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='エ' ? ' index' : '')}>エ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['オ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_オ_pc" data-kana="オ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='オ' ? ' index' : '')}>オ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">カ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['カ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_カ_pc" data-kana="カ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='カ' ? ' index' : '')}>カ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['キ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_キ_pc" data-kana="キ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='キ' ? ' index' : '')}>キ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ク']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ク_pc" data-kana="ク" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ク' ? ' index' : '')}>ク</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ケ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ケ_pc" data-kana="ケ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ケ' ? ' index' : '')}>ケ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['コ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_コ_pc" data-kana="コ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='コ' ? ' index' : '')}>コ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">サ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['サ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_サ_pc" data-kana="サ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='サ' ? ' index' : '')}>サ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['シ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_シ_pc" data-kana="シ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='シ' ? ' index' : '')}>シ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ス']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ス_pc" data-kana="ス" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ス' ? ' index' : '')}>ス</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['セ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_セ_pc" data-kana="セ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='セ' ? ' index' : '')}>セ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ソ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ソ_pc" data-kana="ソ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ソ' ? ' index' : '')}>ソ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">タ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['タ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_タ_pc" data-kana="タ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='タ' ? ' index' : '')}>タ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['チ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_チ_pc" data-kana="チ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='チ' ? ' index' : '')}>チ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ツ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ツ_pc" data-kana="ツ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ツ' ? ' index' : '')}>ツ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['テ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_テ_pc" data-kana="テ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='テ' ? ' index' : '')}>テ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ト']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ト_pc" data-kana="ト" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ト' ? ' index' : '')}>ト</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">ナ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ナ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ナ_pc" data-kana="ナ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ナ' ? ' index' : '')}>ナ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ニ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ニ_pc" data-kana="ニ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ニ' ? ' index' : '')}>ニ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ヌ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ヌ_pc" data-kana="ヌ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ヌ' ? ' index' : '')}>ヌ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ネ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ネ_pc" data-kana="ネ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ネ' ? ' index' : '')}>ネ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ノ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ノ_pc" data-kana="ノ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ノ' ? ' index' : '')}>ノ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">ハ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ハ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ハ_pc" data-kana="ハ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ハ' ? ' index' : '')}>ハ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ヒ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ヒ_pc" data-kana="ヒ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ヒ' ? ' index' : '')}>ヒ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['フ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_フ_pc" data-kana="フ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='フ' ? ' index' : '')}>フ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ヘ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ヘ_pc" data-kana="ヘ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ヘ' ? ' index' : '')}>ヘ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ホ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ホ_pc" data-kana="ホ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ホ' ? ' index' : '')}>ホ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">マ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['マ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_マ_pc" data-kana="マ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='マ' ? ' index' : '')}>マ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ミ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ミ_pc" data-kana="ミ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ミ' ? ' index' : '')}>ミ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ム']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ム_pc" data-kana="ム" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ム' ? ' index' : '')}>ム</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['メ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_メ_pc" data-kana="メ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='メ' ? ' index' : '')}>メ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['モ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_モ_pc" data-kana="モ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='モ' ? ' index' : '')}>モ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">ヤ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ヤ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ヤ_pc" data-kana="ヤ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ヤ' ? ' index' : '')}>ヤ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ユ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ユ_pc" data-kana="ユ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ユ' ? ' index' : '')}>ユ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ヨ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ヨ_pc" data-kana="ヨ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ヨ' ? ' index' : '')}>ヨ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">ラ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ラ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ラ_pc" data-kana="ラ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ラ' ? ' index' : '')}>ラ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['リ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_リ_pc" data-kana="リ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='リ' ? ' index' : '')}>リ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ル']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ル_pc" data-kana="ル" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ル' ? ' index' : '')}>ル</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['レ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_レ_pc" data-kana="レ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='レ' ? ' index' : '')}>レ</span></li>
                    <li className={"kanaListContent__item"+(typeof lecturerList['ロ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ロ_pc" data-kana="ロ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ロ' ? ' index' : '')}>ロ</span></li>
                </ul>
            </li>
            <li className="kanaList__item">
                <div className="kanaList__row">ワ行</div>
                <ul className="kanaListContent flex">
                    <li className={"kanaListContent__item"+(typeof lecturerList['ワ']==='undefined' ? ' disabled' : '')}><span id="seminar_main_seminar_lecturer_kana_ワ_pc" data-kana="ワ" onClick={clickKanaChild} className={'kanaListContent__link'+(currentKanaChild==='ワ' ? ' index' : '')}>ワ</span></li>                    
                </ul>
            </li>
        </ul>
    );
}