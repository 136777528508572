export const LecturerList = ({className, lecturerList, selectLecturer, device}) =>
{
    const lecturerListHtml = () =>
    {
        if(typeof lecturerList==='undefined'){
            return;
        }
        if(lecturerList.length){
            return lecturerList.map(row => {
                return (
                    <li key={row.KOUSHI_CD} className="lecturerList__item">
                        <span
                            id={'seminar_main_seminar_lecturer_'+row.KOUSHI_NM.replace(' ', '')+'_'+device}
                            className={'lecturerList__link'+row.className}
                            data-koushi_nm={row.KOUSHI_NM}
                            onClick={selectLecturer}
                        >{row.KOUSHI_NM}</span>
                    </li>
                );
            })
        }else{
            return (
                <li className="lecturerList__item"><span className="lecturerList__link">検索結果なし</span></li>
            );
        }
    }

    return (
        <ul className={className}>
            {lecturerListHtml()}
        </ul>
    );
}